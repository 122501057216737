// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDU0WxjhJCoCjwXoziHQKh3MwYlp-Oq1yM",
  authDomain: "galenmyburgh-6456c.firebaseapp.com",
  projectId: "galenmyburgh-6456c",
  storageBucket: "galenmyburgh-6456c.appspot.com",
  messagingSenderId: "143103462443",
  appId: "1:143103462443:web:5f89d1abd9699667112e6e",
  measurementId: "G-2JSJRJ3P32"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firestore
const db = getFirestore(app);

export { db };