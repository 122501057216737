import React, { useState } from 'react';
import styled from 'styled-components';
import { updateGuestRSVP } from '../services/guestService';

const ModalBackground = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")}; 
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 80%;
  text-align: center;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #0A2F51;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 10px;
`;

const CancelButton = styled.button`
  padding: 8px 18px;
  background-color: white;
  color: #333;
  border: 2px solid #0A2F51;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 5px
`;

const EetVoorkeure = ({ show, handleClose, handleNext, handleKlaar}) => {
  const guest = JSON.parse(localStorage.getItem('guest'));
  const [allergie, setAllergie] = useState('');
  const [hetAllergie, setHetAllergie]= useState(false);

  const handleHetAllergie = async() =>
  {
    setHetAllergie(true);
    // const updates = {
    //   ontbytSondag: true,
    //   dateUpdated: new Date(), // Current date and time
    // };

    // await updateGuestRSVP(guest.id, updates);
    // handleNext();
  }
  const handleGeenAllergie = async() =>
  {
    const updates = {
      hetKosAllergie: false,
      dateUpdated: new Date(), // Current date and time
    };

    await updateGuestRSVP(guest.id, updates);
    handleNext();
  }
  const handleSubmit = async() =>
  {
    const updates = {
      kosAllergie: allergie,
      dateUpdated: new Date(), // Current date and time
    };

    await updateGuestRSVP(guest.id, updates);
    handleNext();
  }

  return (
    <ModalBackground show={show}>
      <ModalContent>
        <h2>Kos voorkeure:</h2>
        <p>Het {guest.aanspreking} enige kos allergieë?</p>
        {hetAllergie && <>
        <br/>
          <input onChange={(val)=> setAllergie(val.target.value)} type="text" placeholder="Kos allergieë" />
          <br/>
          <Button onClick={handleSubmit}>Voltooi</Button>
        </>}
        {!hetAllergie &&
        <>
            <CancelButton onClick={handleGeenAllergie}>Nee</CancelButton>
            <Button onClick={handleHetAllergie}>Ja</Button>
        </>
        }
      </ModalContent>
    </ModalBackground>
  );
};

export default EetVoorkeure;
