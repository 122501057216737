import React, { useState } from 'react';
import styled from 'styled-components';
import { updateGuestRSVP } from '../services/guestService';

const ModalBackground = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")}; 
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 80%;
  text-align: center;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #0A2F51;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 5px;
`;

const CancelButton = styled.button`
  padding: 5px 10px;
  background-color: white;
  color: #333;
  border: 2px solid black;
  cursor: pointer;
  margin-top: 10px;
`;

const SadMessageModal = ({ show, handleClose }) => (
  <ModalBackground show={show}>
    <ModalContent>
      <h2>Ons is jammer om te hoor dat jy nie kan kom nie.</h2>
      <br/>
      <CancelButton onClick={handleClose}>Maak Toe</CancelButton>
    </ModalContent>
  </ModalBackground>
);

const AlternatieweVerblyfModal = ({ show, handleClose, guestId, handleNext, handleKlaar }) => {
  const [showSadMessage, setShowSadMessage] = useState(false);
  const guest = JSON.parse(localStorage.getItem('guest'));


  // Function to handle the "Nee" option
  const handleNoResponse = async () => {
    if (!guestId) {
      console.error("guestId is undefined or invalid.");
      return;
    }

    const updates = {
      troueBywoon: false,
      dateUpdated: new Date(), // Current date and time
    };

    try {
        console.log("guestId:", guestId);

      await updateGuestRSVP(guestId, updates);
      setShowSadMessage(true); // Show the sad message modal
    } catch (error) {
      console.error("Error updating RSVP:", error);
    }
  };

  // Handle closing the sad message modal
  const handleSadMessageClose = () => {
    setShowSadMessage(false);
    handleClose(); // Close both modals
  };

  return (
    <>
      <ModalBackground show={show}>
    <ModalContent>
      <h2>Verblyf Opsies</h2>
      <br/>
      <p>Beskikbare verblyf in die area: </p>
      <br/>

      <p>Gastehuis naam: Casa Mia</p>
      <p>Skakel: <a href='https://www.casamiaestate.com/'>Casa Mia Estate</a> </p>
      <p>Afstand: 4km</p>
      <br/>
      <p>Gastehuis naam: Aloe Penny</p>
      <p>Skakel: <a href='https://www.lekkeslaap.co.za/akkommodasie/aloe-penny?holder=1'>Lekke Slaap</a> </p>
      <p>Afstand: 9 km</p>
      <br/>
      <p>Gastehuis naam: Ouwerf Plaasstal</p>
      <p>Skakel: <a href='https://www.lekkeslaap.co.za/akkommodasie/ouwerf-plaasstal'>Lekke Slaap</a> </p>
      <p>Afstand: 11.9 km</p>
      <br/>
      <p>Gastehuis naam: At The Rocks Country Estate</p>
      <p>Skakel: <a href='https://www.lekkeslaap.co.za/akkommodasie/at-the-rocks-country-estate?holder=1'>Lekke Slaap</a> </p>
      <p>Afstand: 14.6 km</p>
      <br/>
      <p>Gastehuis naam: Die Skaap Kraal</p>
      <p>Skakel: <a href='https://www.lekkeslaap.co.za/akkommodasie/die-skaap-kraal?holder=1'>Lekke Slaap</a> </p>
      <p>Afstand: 18.8 km</p>
      <br/>
      <p>Gastehuis naam: Diamantvallei Landgoed</p>
      <p>Skakel: <a href='https://www.safarinow.com/go/diamantvallei-landgoed-self-catering-rayton/'>Lekke Slaap</a> </p>
      <p>Afstand: 17.3 km</p>
      <br/>
      
      <CancelButton onClick={handleKlaar}>Maak Toe</CancelButton>
    </ModalContent>
  </ModalBackground>
    </>
  );
};

export default AlternatieweVerblyfModal;
