import { db } from './firebase';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';

// Fetch guest details by name
export const getGuestByName = async (guestName) => {
  try {
    const firstName = guestName.trim().split(' ')[0];

    const guestsCollection = collection(db, 'guests');

    // Query for gas_naam
    const guestNaamQuery = query(
      guestsCollection,
      where('gas_metgesel', '==', firstName)
    );

    // Execute the query
    const guestNaamSnapshot = await getDocs(guestNaamQuery);

    if (!guestNaamSnapshot.empty) {
      const guestDoc = guestNaamSnapshot.docs[0];
      return { id: guestDoc.id, ...guestDoc.data() };
    }

    // Query for gas_metgesel
    const guestMetgeselQuery = query(
      guestsCollection,
      where('gas_metgesel', '==', firstName)
    );

    // Execute the query
    const guestMetgeselSnapshot = await getDocs(guestMetgeselQuery);

    if (!guestMetgeselSnapshot.empty) {
      const guestDoc = guestMetgeselSnapshot.docs[0];
      return { id: guestDoc.id, ...guestDoc.data() };
    }

    // If no guest is found
    console.log('Guest not found.');
    return null;
  } catch (error) {
    console.error("Error fetching guest:", error);
    throw new Error("Unable to fetch guest details");
  }
};


export const updateGuestRSVP = async (guestId, updates) => {
    try {
      const guestDoc = doc(db, 'guests', guestId); // Reference to the specific guest document by ID
      await updateDoc(guestDoc, updates); // Update fields in Firestore
    } catch (error) {
      console.error("Error updating guest:", error);
      throw new Error("Unable to update guest RSVP");
    }
};