import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { getGuestByName, updateGuestRSVP } from '../services/guestService';

// Firebase
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from '../services/firebase';

const ModalBackground = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")}; 
  position: fixed;
  inset: 0; /* Shorthand for top, right, bottom, left */
  background-color: rgba(0, 0, 0, 0.6); 
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow: hidden;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;          
  max-width: 500px;    
  text-align: center;
  margin: 0 auto;      
  box-sizing: border-box;
  position: relative;  
`;

const Input = styled.input`
  padding: 10px;
  margin: 10px 0;       
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc; /* Added border */
  border-radius: 4px;
  outline: none;
  
  &:focus {
    border-color: #0A2F51;
    box-shadow: 0 0 5px rgba(10, 47, 81, 0.5);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;      
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #0A2F51;
  color: white;
  border: none;
  cursor: pointer;
  flex: 1;              
  max-width: 150px;     
`;

const CancelButton = styled(Button)`
  background-color: white;
  color: #0A2F51;
  border: 2px solid #0A2F51;
`;

const ErrorMessage = styled.p`
  color: red;
  margin-top: 10px;
`;

// Keyframes for the spinning animation
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Loader container
const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column; 
  align-items: center;
  height: 100%;
`;

// Circle Spinner styles
const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #0A2F51;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
`;

// Loading text styles
const LoadingText = styled.p`
  margin-top: 10px;
  font-size: 16px;
  color: #0A2F51;
`;

const Loader = () => (
  <LoaderWrapper>
    <Spinner />
    <br/>
    <LoadingText>Wag 'n bietjie...</LoadingText>
  </LoaderWrapper>
);

const SadMessageModal = ({ show, handleClose }) => (
  <ModalBackground show={show}>
    <ModalContent>
      <h2>Ons is jammer om te hoor dat jy nie meer kan kom nie.</h2>
      <br/>
      <CancelButton onClick={handleClose}>Maak Toe</CancelButton>
    </ModalContent>
  </ModalBackground>
);

const PersoonInligtingModal = ({ show, handleClose, handleNext }) => {
  const [guestName, setGuestName] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [verifyGuestName, setVerifyGuestName] = useState(false);
  const [guest, setGuest] = useState(null);
  const [guestId, setGuestId] = useState('');
  const [showSadMessage, setShowSadMessage] = useState(false);

  // Function to handle guest name submission and fetch details
  const handleGuestSubmission = async (guestName) => {
    setLoading(true); // Start loader
    const guestData = await getGuestByName(guestName);
    if (guestData) {
      setGuest(guestData);
      setGuestId(guestData.id);
      localStorage.setItem('guest', JSON.stringify(guestData));
      setVerifyGuestName(true);
      setError("");
    } else {
      setError("Skies, jou naam is nie op die gastelys nie...");
    }
    setLoading(false); // Stop loader
  };

  const getGuestByName = async (guestName) => {
    try {
      const guestsRef = collection(db, 'guests');
      const guestQuery = query(guestsRef, where('gas_naam', '==', guestName));
      const querySnapshot = await getDocs(guestQuery);
      
      
      const guestMetgeselQuery = query(guestsRef, where('gas_metgesel', '==', guestName));
      const queryMetgeselSnapshot = await getDocs(guestMetgeselQuery);

      if (!querySnapshot.empty) {
        const guestDoc = querySnapshot.docs[0];
        return { id: guestDoc.id, ...guestDoc.data() };
      } else if (!queryMetgeselSnapshot.empty)
        {
          const guestDoc = queryMetgeselSnapshot.docs[0];
          return { id: guestDoc.id, ...guestDoc.data() };
        } 
      else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching guest:", error);
      return null;
    }
  };

  const handleSubmit = async () => {
    if (!guestName.trim()) {
      setError("Vul asseblief jou naam in.");
      return;
    }
    await handleGuestSubmission(guestName);
  };

  const handleOnTroueToeKom = async () => {
    setLoading(true); // Start loader
    const updates = {
      troueBywoon: true,
      dateUpdated: new Date(),
    };
    await updateGuestRSVP(guest.id, updates);
    handleNext();
    setLoading(false); // Stop loader
  };

  const handleOnNieTroueToeKom = async () => {
    if (!guestId) {
      console.error("guestId is undefined or invalid.");
      return;
    }
    setLoading(true); // Start loader
    const updates = {
      troueBywoon: false,
      dateUpdated: new Date(),
    };

    try {
      await updateGuestRSVP(guestId, updates);
      setShowSadMessage(true);
    } catch (error) {
      console.error("Error updating RSVP:", error);
    }
    setLoading(false); // Stop loader
  };

  const handleSadMessageClose = () => {
    setShowSadMessage(false);
    handleClose();
  };

  return (
    <>
      <ModalBackground show={show}>
        <ModalContent>
          {loading ? (
            <Loader /> // Display loader while waiting for Firebase
          ) : (
            !verifyGuestName ? (
              <>
                <h2>Sleutel jou naam in</h2>
                <br/>
                <p>Vul asseblief jou besonderhede in om te bevestig of jy op die gastelys is en om te sien wat ons vir jou het.</p>
                <br/>
                <Input
                  type="text"
                  value={guestName}
                  onChange={(e) => setGuestName(e.target.value)}
                  placeholder="Sleutel in jou naam..."
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
                <ButtonContainer>
                  <CancelButton onClick={handleClose}>Maak Toe</CancelButton>
                  <Button onClick={handleSubmit}>Volgende</Button>
                </ButtonContainer>
              </>
            ) : (
              <>
                <h3>Welkom</h3>
                <br />
                <p>Ons het die volgende op die gastelys:</p>
                <strong>
                  <p>{guest.gas_naam}{guest.gas_metgesel && ` & ${guest.gas_metgesel}`}</p>
                </strong>
                <br />
                <hr />
                <br />
                <p>Gaan {guest.aanspreking} na ons troue toe kom?</p>
                <ButtonContainer>
                  <CancelButton onClick={handleOnNieTroueToeKom}>Nee</CancelButton>
                  <Button onClick={handleOnTroueToeKom}>Ja</Button>
                </ButtonContainer>
              </>
            )
          )}
        </ModalContent>
      </ModalBackground>
      <SadMessageModal show={showSadMessage} handleClose={handleSadMessageClose} />
    </>
  );
};

export default PersoonInligtingModal;
