import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { updateGuestRSVP } from '../services/guestService';

const ModalBackground = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")}; 
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 80%;
  text-align: center;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #0A2F51;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  ${({ disabled }) => disabled && `
    opacity: 0.6;
    cursor: not-allowed;
  `}
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  align-items: center;
  justify-content: center;
`;

const Label = styled.label`
  margin-bottom: 10px;
  font-size: 1rem;
  display: flex;
  align-items: center;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const Subtotal = styled.p`
  font-weight: bold;
  margin-top: 20px;
`;

const LoadingMessage = styled.p`
  margin-top: 20px;
  font-size: 1.2rem;
  color: #0A2F51;
`;

const OornagModal = ({ show, handleClose, handleNext, handleKlaar, updateGuestData }) => {
  const [stayFriday, setStayFriday] = useState(false);
  const [staySaturday, setStaySaturday] = useState(false);
  const [stayNone, setStayNone] = useState(false);
  const [stayAlternatieweOpsies, setStayAlternatieweOpsies] = useState(false);
  const [subtotal, setSubtotal] = useState(0);
  const [amountTotal, setAmountTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const guest = JSON.parse(localStorage.getItem('guest'));

  // Prices for the nights
  const fridayPrice = 630;
  const saturdayPrice = 630;

  // Calculate subtotal based on selections
  useEffect(() => {
    let total = 0;
    if (stayFriday) total += fridayPrice;
    if (staySaturday) total += saturdayPrice;
    setSubtotal(total);

    if (guest.gas_metgesel) {
      setAmountTotal(total * 2);
    } else {
      setAmountTotal(total);
    }
  }, [stayFriday, staySaturday, guest.gas_metgesel]);

  const handleSubmit = async () => {
    setLoading(true); // Start loading indicator
    try {
      let choices = {};
      var total = subtotal;
      if(guest.gas_metgesel != null && guest.gas_metgesel != ''){
        total = subtotal * 2;
      }
      if (guest.venueVerblyf) {
        choices = {
          stayFriday,
          staySaturday,
          stayNone,
          subtotal,
          total,
        };
      } else {
        choices = {
          stayAlternatieweOpsies,
          stayNone,
        };
      }

      const updates = {
        oornag: choices,
        dateUpdated: new Date(),
      };
      await updateGuestRSVP(guest.id, updates);

      // Update the local guest object
      const updatedGuest = {
        ...guest,
        ...updates, // Merge the updates into the guest object
      };

      // Save the updated guest object back to local storage
      localStorage.setItem('guest', JSON.stringify(updatedGuest));

      updateGuestData(updatedGuest);

      if (stayNone || (!guest.venueVerblyf && !stayAlternatieweOpsies)) {
        setLoading(false); // Stop loading indicator
        handleKlaar();
        return;
      }
      setLoading(false); // Stop loading indicator
      handleNext(choices);
    } catch (error) {
      console.error("Error updating guest RSVP:", error);
      setLoading(false); // Stop loading indicator
      // Optionally display an error message to the user
    }
  };

  const setFuncFriday = (e) => {
    if (e.target.checked) setStayNone(false);
    setStayFriday(e.target.checked);
  };

  const setFuncSaturday = (e) => {
    if (e.target.checked) setStayNone(false);
    setStaySaturday(e.target.checked);
  };

  const setFuncAlternatieweOpsies = (e) => {
    if (e.target.checked) setStayNone(false);
    setStayAlternatieweOpsies(e.target.checked);
  };

  const setFuncNone = (e) => {
    if (e.target.checked) {
      setStayFriday(false);
      setStaySaturday(false);
      setStayAlternatieweOpsies(false);
    }
    setStayNone(e.target.checked);
  };

  return (
    <ModalBackground show={show}>
      <ModalContent>
        <h2>Wil {guest.aanspreking} oornag?</h2>
        <br />
        <p>Kies opsies:</p>
        <CheckboxWrapper>
          <center>
            {guest.venueVerblyf && (
              <>
                <Label>
                  <Checkbox
                    type="checkbox"
                    checked={stayFriday}
                    onChange={setFuncFriday}
                  />
                  Vrydag aand - R{fridayPrice}.00 {guest.gas_metgesel && <>pp</>}
                </Label>
                <Label>
                  <Checkbox
                    type="checkbox"
                    checked={staySaturday}
                    onChange={setFuncSaturday}
                  />
                  Saterdag aand - R{saturdayPrice}.00{' '}
                  {guest.gas_metgesel && <>pp</>}
                </Label>
              </>
            )}
            {!guest.venueVerblyf && (
              <>
                <Label>
                  <Checkbox
                    type="checkbox"
                    checked={stayAlternatieweOpsies}
                    onChange={setFuncAlternatieweOpsies}
                  />
                  Ja - Ek wil graag die opsies sien.
                </Label>
              </>
            )}
            <Label>
              <Checkbox
                type="checkbox"
                checked={stayNone}
                onChange={setFuncNone}
              />
              Gaan nie oornag nie
            </Label>
          </center>
        </CheckboxWrapper>
        {guest.venueVerblyf && (
          <>
            {subtotal > 0 && (
              <Subtotal>
                Subtotaal: R{subtotal}{' '}
                {guest.gas_metgesel && <>per persoon</>}
              </Subtotal>
            )}
            {guest.gas_metgesel && amountTotal > 0 && (
              <Subtotal>Totaal: R{amountTotal} </Subtotal>
            )}
          </>
        )}

        {/* Show loading message when loading */}
        {loading && <LoadingMessage>Wag 'n bietjie...</LoadingMessage>}

        {/* Disable the button when loading */}
        {(stayFriday ||
          staySaturday ||
          stayNone ||
          stayAlternatieweOpsies) && (
          <Button onClick={handleSubmit} disabled={loading}>
            Volgende
          </Button>
        )}
      </ModalContent>
    </ModalBackground>
  );
};

export default OornagModal;
