import React from 'react';
import styled from 'styled-components';
import { updateGuestRSVP } from '../services/guestService';

const ModalBackground = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")}; 
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 80%;
  text-align: center;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #0A2F51;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 10px;
`;

const CancelButton = styled.button`
  padding: 8px 18px;
  background-color: white;
  color: #333;
  border: 2px solid #0A2F51;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 5px
`;

const OntbytModal = ({ show, handleClose, handleNext, handleKlaar, updateGuestData}) => {
  const guest = JSON.parse(localStorage.getItem('guest'));

  const handleOnOntbyt = async() =>
  {
    const updates = {
      ontbytSondag: true,
      dateUpdated: new Date(), // Current date and time
    };

    await updateGuestRSVP(guest.id, updates);

     // Update the local guest object
     const updatedGuest = {
      ...guest,
      ...updates, // Merge the updates into the guest object
    };

    // Save the updated guest object back to local storage
    localStorage.setItem('guest', JSON.stringify(updatedGuest));

    updateGuestData(updatedGuest);
    handleNext();
  }
  const handleNieOntbyt = async() =>
  {
    const updates = {
      ontbytSondag: false,
      dateUpdated: new Date(), // Current date and time
    };

    await updateGuestRSVP(guest.id, updates);

     // Update the local guest object
     const updatedGuest = {
      ...guest,
      ...updates, // Merge the updates into the guest object
    };

    // Save the updated guest object back to local storage
    localStorage.setItem('guest', JSON.stringify(updatedGuest));

    updateGuestData(updatedGuest);
    handleKlaar();
  }

  return (
    <ModalBackground show={show}>
      <ModalContent>
        <h2>Gaan {guest.aanspreking} Sondag oggend saam met ons ontbyt eet?</h2>
        {/* <p>Wil jy saam met ons ontbyt geniet op Sondag oggend?</p> */}
        <CancelButton onClick={handleNieOntbyt}>Nee</CancelButton>
        <Button onClick={handleOnOntbyt}>Ja</Button>
        {/* <CancelButton onClick={handleClose}>Maak Toe</CancelButton> */}
      </ModalContent>
    </ModalBackground>
  );
};

export default OntbytModal;
