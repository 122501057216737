import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Slider from 'react-slick';
import bedroom3 from '../../images/troue/venues/bedroom-3.webp';
import bedroom2 from '../../images/troue/venues/bedrooom-2.webp';
import kitchen from '../../images/troue/venues/kitchen.webp';
import lounge from '../../images/troue/venues/lounge.webp';
import swimmingPool from '../../images/troue/venues/swimming-pool.webp';
import yeard from '../../images/troue/venues/yeard.webp';
import anria_portrait from '../../images/troue/ons/anria_portrait.jpg';
import galen_portrait from '../../images/troue/ons/galen_portait.jpg';
import g_a from "../../images/troue/ons/g_a.jpg";
import g_a_saam2 from "../../images/troue/ons/g_a_saam2.jpg";
import g_a_saam3 from "../../images/troue/ons/g_a_saam3.jpg";
import g_a_saam_groot from "../../images/troue/ons/g_a_saam_groot.jpg";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "../../styles/troue.css";
import TrouFooter from "../../components/Troue/Footer";
import { Link } from 'react-scroll'; // For smooth scrolling

// Firebase
import { collection, query, where, getDocs } from "firebase/firestore"; // Import Firestore utilities
import { db } from './services/firebase'; // Import Firestore instance

// Modals
import PersoonInligtingModal from "./modals/PersoonlikeInligtingModal";
import BywoonModal from "./modals/BywoonModal";
import OornagModal from "./modals/OornagModal";
import OntbytModal from "./modals/OntbytModal";
import EetVoorkeure from "./modals/EetVoorkeure";
import DankieModal from "./modals/DankieModal";

// Styled components and additional imports
import { FaBars } from 'react-icons/fa'; // Burger menu icon
import AlternatieweVerblyfModal from "./modals/AlternatieweVerblyfModal";
import BankBesonderhedeModal from "./modals/BankBesonderhedeModal";

// Define the blue color once to reuse
const themeColor = '#0A2F51'; // Adjust this to your desired blue color

// Styled components
const CoverImage = styled.div`
  background-image: url(${g_a});
  background-size: cover;
  background-position: center;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.6);
  font-size: 8rem;
  font-weight: semi-bold;
  text-align: center;
  font-family: 'Great Vibes', cursive; 

  @media (max-width: 768px) {
    font-size: 4rem;
  }

  @media (max-width: 480px) {
    font-size: 2.5rem;
  }
`;

// Sticky header
const Header = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  background-color: ${({ isSticky }) => (isSticky ? 'white' : 'transparent')};
  color: ${({ isSticky }) => (isSticky ? themeColor : 'white')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  box-shadow: ${({ isSticky }) => (isSticky ? '0 2px 4px rgba(0,0,0,0.1)' : 'none')};
  transition: background-color 0.3s, color 0.3s;
  z-index: 1000;
  max-width: 95%;
`;

const Logo = styled.div`
  font-family: 'Great Vibes', cursive;
  font-size: 2rem;
  color: inherit; // Use inherited color
`;

const BurgerMenuIcon = styled(FaBars)`
  font-size: 1.5rem;
  color: inherit; // Use inherited color
  cursor: pointer;
`;

const NavMenu = styled.nav`
  position: fixed;
  top: 0;
  
  left: ${({ open }) => (open ? '0' : '-100%')};
  width: 80%; // Adjusted width
  max-width: 300px; // Added max-width
  height: 100vh;
  background-color: white;
  padding-top: 60px;
  transition: left 0.3s;
  z-index: 999;
  box-sizing: border-box; // Include padding in width calculations
  overflow-y: auto; // Allow scrolling if content exceeds viewport height
`;

const NavList = styled.ul`
  list-style: none;
  padding: 0 20px;
`;

const NavItem = styled.li`
  margin: 20px 0;
`;

const NavLinkStyled = styled(Link)`
  text-decoration: none;
  font-size: 1.5rem;
  color: ${themeColor};
  cursor: pointer;

  &:hover {
    color: ${themeColor};
  }
`;

const Section = styled.div`
  padding: 30px 0; // Reduced padding from 50px to 30px
  text-align: center;
  box-sizing: border-box;
`;

const StoryContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
`;

const StoryImage = styled.img`
  width: 100%;
  height: auto;
  flex: 1;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const StoryText = styled.div`
  flex: 1;
  text-align: center;
  font-size: 1.2rem;
  padding: 0 20px;
  box-sizing: border-box;
  max-width: 90%;

  @media (max-width: 768px) {
    padding: 20px;
    font-size: 1rem;
    max-width: 100%;
  }

  word-wrap: break-word;
  overflow-wrap: break-word;
  margin: 0 auto;
`;

const RSVPButton = styled.button`
  padding: 10px 20px;
  margin: 10px;
  background-color: white;
  color: #333;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.5rem;
  font-family: 'Quicksand', sans-serif;
  max-width: 100%;
  box-sizing: border-box;

  @media (max-width: 480px) {
    font-size: 1.2rem;
    padding: 8px 16px;
  }
`;

const RSVP_text = styled.h2`
  font-size: 3rem;
  color: white;
  text-align: center;
  max-width: 90%; /* Adjusted to limit the width */
  margin: 0 auto;   /* Center the text */
  word-wrap: break-word; /* Ensure words wrap */
  
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

const CarouselContainer = styled.div`
  width: 80%;  
  margin: 0 auto;  
  padding: 20px 0;  

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px 0;
  }
`;

const CarouselImage = styled.img`
  width: 100%;
  height: auto;
`;

const VenueCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, 
    slidesToScroll: 1, 
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <CarouselContainer>
      <Slider {...settings}>
        <div style={{ padding: '10px' }}>
          <CarouselImage src={bedroom3} alt="Bedroom 3" />
        </div>
        <div style={{ padding: '10px' }}>
          <CarouselImage src={bedroom2} alt="Bedroom 2" />
        </div>
        <div style={{ padding: '10px' }}>
          <CarouselImage src={kitchen} alt="Kitchen" />
        </div>
        <div style={{ padding: '10px' }}>
          <CarouselImage src={lounge} alt="Lounge" />
        </div>
        <div style={{ padding: '10px' }}>
          <CarouselImage src={swimmingPool} alt="Swimming Pool" />
        </div>
        <div style={{ padding: '10px' }}>
          <CarouselImage src={yeard} alt="Yard" />
        </div>
      </Slider>
    </CarouselContainer>
  );
};

const CountdownTimer = styled.div`
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
  padding: 20px; /* Add padding to prevent touching the sides */
  box-sizing: border-box; /* Ensure padding is included in width calculations */
`;

const TimerBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 10px;
  min-width: 80px; /* Ensure minimum width for the boxes */
  background-color: #f9f9f9;
  box-sizing: border-box;
  font-size: 1.2rem;

  div:first-child {
    font-weight: bold;
    font-size: 2rem; /* Make the countdown number larger */
    color: ${themeColor}; /* Changed to theme color */
  }

  div:last-child {
    margin-top: 5px;
    font-size: 1rem;
    text-transform: uppercase;
    color: ${themeColor}; /* Changed to theme color */
  }

  hr {
    width: 80%;
    border: none;
    border-top: 1px solid #ccc;
    margin: 10px 0;
  }
`;

const MapContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  position: relative;
`;

const ResponsiveIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

const RSVPContainer = styled.div`
  position: absolute;
  top: 3rem;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 20px; /* Added padding to prevent text from touching edges */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  box-sizing: border-box; /* Ensure padding is included in width calculations */
`;

const FullWidthImageSection = styled.div`
  position: relative;
  width: 100%;
  height: 60vh; /* Adjust as needed */
  background-image: url(${g_a_saam_groot});
  background-size: cover;
  background-position: center;

  @media (max-width: 768px) {
    height: 50vh;
  }

  @media (max-width: 480px) {
    height: 40vh;
  }
`;

const WeddingPartyContainer = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: center;
  gap: 50px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
`;

const PortraitImage = styled.img`
  width: 100%;
  max-width: 300px;
  height: auto;

  @media (max-width: 480px) {
    max-width: 80%;
  }
`;

const SectionHeading = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
  max-width: 90%;
  margin: 0 auto;

  @media (max-width: 768px) {
    font-size: 1.75rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const SectionParagraph = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 90%; /* Changed to percentage */
  margin: 0 auto; /* Center the paragraph */
  padding: 0 20px; /* Added padding */

  @media (max-width: 768px) {
    font-size: 1.1rem;
    padding: 0 15px;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
    padding: 0 10px;
  }
`;

// Countdown logic
const calculateTimeLeft = () => {
  const weddingDate = new Date("2025-02-08T15:00:00");
  const now = new Date();
  const difference = weddingDate - now;
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      Maande: Math.floor(difference / (1000 * 60 * 60 * 24 * 30)),
      Dae: Math.floor((difference / (1000 * 60 * 60 * 24)) % 30),
      Ure: Math.floor((difference / (1000 * 60 * 60)) % 24),
      Minute: Math.floor((difference / 1000 / 60) % 60),
      Sekondes: Math.floor((difference / 1000) % 60),
    };
  }
  return timeLeft;
};

const Troue = () => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [currentModal, setCurrentModal] = useState(0); // 1 = PersoonInligting, 2 = Bywoon, etc.
  const [isSticky, setIsSticky] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  // State variables for modal data
  const [persoonInligtingData, setPersoonInligtingData] = useState({});
  const [bywoonData, setBywoonData] = useState({});
  const [oornagData, setOornagData] = useState({});
  const [ontbytData, setOntbytData] = useState({});
  const [eetVoorkeureData, setEetVoorkeureData] = useState({});

  // Guest Deets
  const [guest, setGuest] = useState(JSON.parse(localStorage.getItem('guest')));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    const handleScroll = () => {
      if (window.pageYOffset >= 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      clearInterval(timer);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNext = (data) => {
    // Save data from the modal
    switch (currentModal) {
      case 1:
        setPersoonInligtingData(data);
        break;
      case 2:
        setBywoonData(data);
        break;
      case 3:
        setOornagData(data);
        break;
      default:
        break;
    }
    if (((currentModal == 2) && (data.stayAlternatieweOpsies == true))) {
      setCurrentModal(6);
    } else if (currentModal === 4 && !guest.oornag.stayNone) {
      setCurrentModal(7);
    }


    else {
      setCurrentModal(currentModal + 1); // Move to next modal
    }
  };

  const handleClose = () => {
    setCurrentModal(0); // Close all modals
  };

  const updateGuestData = (updatedGuest) => {
    setGuest(updatedGuest);
    localStorage.setItem('guest', JSON.stringify(updatedGuest));
  };

  const handleKlaar = (data) => {
    // Save the data from the last modal
    if(currentModal ===  3){
      setOntbytData(data);
     
      if(!guest.oornag.stayAlternatieweOpsies){
        setCurrentModal(7);
        return;
      }
    }
    if(currentModal === 4 && !guest.oornag.stayNone){
      setCurrentModal(7);
        return;
    }

    // Handle the final submission
    // For example, save all data to the database
    // Then show the thank you modal
    // TODO: Implement data saving logic here
    setCurrentModal(5); // Show thank you modal
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      {currentModal == 0 && <>
      {/* Header with Burger Menu */}
      <Header isSticky={isSticky}>
        <Logo>Myburgh Bruilof</Logo>
        <BurgerMenuIcon onClick={toggleMenu} />
      </Header>

      {/* Navigation Menu */}
      <NavMenu open={menuOpen}>
        <NavList>
          <NavItem>
          <NavItem>
            <NavLinkStyled to="countdown" smooth={true} duration={500} onClick={toggleMenu}>Aftelling</NavLinkStyled>
          </NavItem>
            <NavLinkStyled to="story" smooth={true} duration={500} onClick={toggleMenu}>Ons Storie</NavLinkStyled>
          </NavItem>
          <NavItem>
            <NavLinkStyled to="rsvp" smooth={true} duration={500} onClick={toggleMenu}>RSVP</NavLinkStyled>
          </NavItem>
          <NavItem>
            <NavLinkStyled to="wedding-party" smooth={true} duration={500} onClick={toggleMenu}>Wedding Party</NavLinkStyled>
          </NavItem>
          <NavItem>
            <NavLinkStyled to="dress-code" smooth={true} duration={500} onClick={toggleMenu}>Dress Code</NavLinkStyled>
          </NavItem>
          <NavItem>
            <NavLinkStyled to="registry" smooth={true} duration={500} onClick={toggleMenu}>Geskenkelys</NavLinkStyled>
          </NavItem>
          <NavItem>
            <NavLinkStyled to="venue-images" smooth={true} duration={500} onClick={toggleMenu}>Venue Images</NavLinkStyled>
          </NavItem>
          <NavItem>
            <NavLinkStyled to="location" smooth={true} duration={500} onClick={toggleMenu}>Ligging</NavLinkStyled>
          </NavItem>
        </NavList>
      </NavMenu>
      </>}

      {/* Cover Image Section */}
      <CoverImage>
        Myburgh Bruilof
      </CoverImage>

      {/* Countdown Timer */}
      <Section id="countdown">
        <br/>
        <CountdownTimer>
          {Object.keys(timeLeft).map((unit, index) => (
            <TimerBox key={index}>
              <div>{timeLeft[unit]}</div>
              <hr/>
              <div>{unit}</div>
            </TimerBox>
          ))}
        </CountdownTimer>
      </Section>

      {/* Our Story Section */}
      <Section id="story">
        <StoryContainer>
          <StoryText>
            <br/>
            <SectionHeading>Ons Storie</SectionHeading>
            <br/>
            <SectionParagraph>
              Ons het mekaar in 2022 ontmoet by ’n konsert – ’n toevallige ontmoeting aangesien nie een van ons gereeld sulke geleenthede bywoon nie.
            </SectionParagraph><br/>

            <SectionParagraph>
              Tussen 2022 en 2024 het ons ons liefde gebou deur baie spesiale oomblikke – van vakansies saam, tot saam oefen, ’n fietsren voltooi en selfs ’n 10km wedloop hardloop.
            </SectionParagraph><br/>

            <SectionParagraph>
              In 2024, op dieselfde plek waar ek haar gevra het om my meisie te wees, het ek haar gevra om my vrou te word. Toe ek haar in Julie 2022 my meisie vra, was dit tydens ’n romantiese sonsondergang met wyn op die plaas. Die aanzoek het by sonop plaasgevind, op daardie selfde berguitkykpunt – dit was net baie kouer, maar net so spesiaal.
            </SectionParagraph><br/>

            <SectionParagraph>
              Nou, in 2025, is ons opgewonde om ons lewe saam te begin. Alhoewel ons effens senuweeagtig is vir die groot stap, kan ons nie wag om dit saam aan te pak nie.
            </SectionParagraph><br/>
          </StoryText>
          <StoryImage src={g_a_saam3} alt="Anria" />
        </StoryContainer>
      </Section>

      {/* Full Width Image Section */}
      <FullWidthImageSection id="rsvp">
        <br/>
        <RSVPContainer>
          <RSVP_text>Bevestig jou teenwoordigheid by ons troue</RSVP_text>
          <RSVPButton onClick={() => setCurrentModal(1)}>RSVP</RSVPButton>
        </RSVPContainer>
      </FullWidthImageSection>

      {currentModal === 1 && (
        <PersoonInligtingModal show={true} handleClose={handleClose} handleNext={handleNext} />
      )}

      {/* BywoonModal */}
      {/* {currentModal === 9 && (
        <BywoonModal show={true} handleClose={handleClose} handleNext={handleNext} guestId={guestId}/>
      )} */}

      {/* OornagModal */}
      {currentModal === 2 && (
        <OornagModal show={true} handleClose={handleClose} handleNext={handleNext} handleKlaar={handleKlaar} updateGuestData={updateGuestData} />
      )}

      {/* OntbytModal */}
      {currentModal === 3 && (
        <OntbytModal show={true} handleClose={handleClose} handleNext={handleNext} handleKlaar={handleKlaar} updateGuestData={updateGuestData}/>
      )}
      {/* Eet voorkeure */}
      {currentModal === 4 && (
        <EetVoorkeure show={true} handleClose={handleClose} handleNext={handleNext} handleKlaar={handleKlaar} updateGuestData={updateGuestData}/>
      )}
      {/* Dankie sê modal */}
      {currentModal === 5 && (
        <DankieModal show={true} handleClose={handleClose} handleNext={handleNext}/>
      )}
      {/* Alternatiewe Verblyf modal */}
      {currentModal === 6 && (
        <AlternatieweVerblyfModal show={true} handleClose={handleClose} handleNext={handleNext} handleKlaar={handleKlaar}/>
      )}

      {/* Bank Besonderhede Modal */}
       {currentModal === 7 && (
        <BankBesonderhedeModal show={true} handleClose={handleClose} handleNext={handleNext} handleKlaar={handleKlaar}/>
      )}

      {/* Wedding Party Section */}
      <Section id="wedding-party">
        <br/>
        <SectionHeading>Wedding Party</SectionHeading>
        <WeddingPartyContainer>
          <div>
            <h3 style={{fontSize: '2rem'}}>Bruid</h3>
            <PortraitImage src={anria_portrait} alt="Bride" />
            <br/>
            <div style={{marginTop:'15px'}} >
              <h3>Strooimeisies</h3>
              <ul style={{listStyle: 'none'}}>
                <li>Bianca</li>
                <li>Johanné</li>
                <li>Tessa</li>
                <li>Suné</li>
                <li>Sheleen</li>
                <li>Sandy</li>
                <li>Siska</li>
              </ul>
            </div>
          </div>
          <div>
            <h3 style={{fontSize: '2rem', marginBottom: '3px'}}>Bruidegom</h3>
            <PortraitImage src={galen_portrait} alt="Groom" />
            <br/>
            <div style={{marginTop:'15px'}}>
              <h3>Strooijonkers</h3>
              <ul style={{listStyle: 'none'}}>
                <li>Wehan</li>
                <li>Zane</li>
                <li>Jean</li>
                <li>Js</li>
                <li>Michael</li>
                <li>Eduan</li>
                <li>Altus</li>
              </ul>
            </div>
          </div>
        </WeddingPartyContainer>
      </Section>

      {/* Dress Code Section */}
      <Section id="dress-code">
        <br/>
        <SectionHeading>Dress Code</SectionHeading>
        <SectionParagraph>Formeel - Skakerings van Blou</SectionParagraph>
      </Section>

      {/* Registry Section */}
      <Section id="registry">
      <br/>
        <SectionHeading>Geskenkelys</SectionHeading>
        <SectionParagraph>
          Jou teenwoordigheid by ons troue is vir ons die grootste geskenk. Indien jy egter graag vir ons 'n geskenk wil gee, sal ons enige kontant bydraes opreg waardeer om ons nuwe reis saam te begin. Baie dankie vir jou liefde en ondersteuning.
        </SectionParagraph>
      </Section>

      {/* Venue Carousel */}
      <Section id="venue-images">
      <br/>
        <center><SectionHeading>Venue Images</SectionHeading></center>
        <VenueCarousel />
      </Section>

      {/* Location Section */}
      <Section id="location">
      <br/>
        <SectionHeading>Ligging</SectionHeading>
        <SectionParagraph>Featherwood Farms</SectionParagraph>
        <SectionParagraph>44 Nooitgedacht Road</SectionParagraph>
        <SectionParagraph>Rayton, 1001</SectionParagraph>
        <MapContainer>
          <ResponsiveIframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1131.053780041062!2d28.553648785715474!3d-25.747516540879476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e955bfa88b4b545%3A0x4dbb89f41753baf3!2sFeatherwood%20Farm%20-%20Wedding%20Venue%20%26%20Accommodation!5e0!3m2!1sen!2sza!4v1695721435172!5m2!1sen!2sza"
            allowFullScreen=""
            loading="lazy"
            title="Wedding Location"
          ></ResponsiveIframe>
        </MapContainer>
      </Section>
      <TrouFooter/>
    </>
  );
};

export default Troue;
